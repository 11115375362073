import "./src/blue-library/styles/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import smoothscroll from 'smoothscroll-polyfill';



export const shouldUpdateScroll = () => {
  // eslint-disable-next-line no-undef
  const isAnchor = window.location.href.includes("#");
  if (!isAnchor) {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }
  return false
}

export const onClientEntry = () => {
 const first = window.document.head.firstChild
  const googleAnalyFunctionTag = document.createElement("script");
  const googleAnalyFunction = "(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date; h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')}; (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c ;})(window,document.documentElement,'async-hide','dataLayer',4000,{'OPT-M2MN69G':true});";
  googleAnalyFunctionTag.append(googleAnalyFunction);
  const googleAnalyStyleTag = document.createElement("style");
  const googleAnalyStyle = ".async-hide { opacity: 0 !important} ";
  googleAnalyStyleTag.append(googleAnalyStyle);
  window.document.head.appendChild(googleAnalyStyleTag);
  window.document.head.appendChild(googleAnalyFunctionTag);
  window.document.head.insertBefore(googleAnalyFunctionTag, first)
  window.document.head.insertBefore(googleAnalyStyleTag, first)

  const para = document.createElement("script");
  // eslint-disable-next-line no-undef
  const att = document.createAttribute("src");
  att.value = "https://www.googleoptimize.com/optimize.js?id=OPT-M2MN69G"
  para.setAttributeNode(att)
  // eslint-disable-next-line no-undef
  // eslint-disable-next-line no-undef
  window.document.head.appendChild(para)
  // eslint-disable-next-line no-undef
  window.document.head.insertBefore(para, first)

  /* SIS-734 issue(2) */
  const messagerScript = document.createElement("script");
  const messengerAtt = document.createAttribute("src");
  messengerAtt.value = "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1";
  messagerScript.setAttributeNode(messengerAtt);
  window.document.head.appendChild(messagerScript);

  smoothscroll.polyfill();
}